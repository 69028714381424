import React, { useState } from 'react';
import { Button, Grid, Paper, TextField, Snackbar, Typography } from '@mui/material';
import { useSearchParams ,Link} from "react-router-dom";
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { resetPassword } from '../../allServices/userServices';

const Newpass = () => {
    const { t } = useTranslation();
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [error, setError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const tokenParam = searchParams.get("token");

    const handleResetPassword = async (e) => {
        e.preventDefault();

        if (!newPassword || !confirmNewPassword) {
            return;
        }

        if (newPassword !== confirmNewPassword) {
            setError(t('validation.passwordMismatch'));
            return;
        }

        try {
            const response = await resetPassword(tokenParam, newPassword);

            setSuccessMessage(t('validation.resetsuccessfully'));
            setError("");
            setTimeout(() => window.location.reload(), 3000); // Reload the page after 3 seconds
        } catch (error) {
            setError(t('validation.resetFailed'));
            console.error("Error reset-password:", error.message);
        }
    };

    const paperStyle = { padding: 50, height: '45vh', width: 500, margin: '50px auto' };

    const handleCloseSnackbar = () => {
        setError("");
        setSuccessMessage("");
    };


    function validation() {
        let nPassword = document.getElementById("np").value;
        let cPassword = document.getElementById("cp").value;
        
        if (nPassword === "") {
            document.getElementById("nperror").innerHTML = t('validation.passwordRequired');
            document.getElementById("nperror").style.color = "red";
        } else {
            document.getElementById("nperror").innerHTML = ""; // Clear error message if password is entered
        }

        if (cPassword === "") {
            document.getElementById("cperror").innerHTML = t('validation.passwordRequired2');
            document.getElementById("cperror").style.color = "red";
        } else {
            document.getElementById("cperror").innerHTML = ""; // Clear error message if password is entered
        }
    }

  

    const clearNPasswordFieldError = () => {
        // Clear the error message for password field
        document.getElementById("nperror").innerHTML = "";
        
      };
    
      const clearCPasswordFieldError = () => {
        // Clear the error message for password field
        document.getElementById("cperror").innerHTML = "";
        
      };

    return (
        <>
            <Grid>
                <Paper elevation={10} style={paperStyle}>
                    <form onSubmit={handleResetPassword}>
                        <Grid align="center">
                            <h1>{t('Reset Password')}</h1>
                            {error && (
                                <Typography variant="body2" color="error" gutterBottom>
                                    {error}
                                </Typography>   
                            )}

                            {successMessage && (
                                <Typography variant="body2" color="primary" gutterBottom>
                                    {successMessage}
                                </Typography>
                            )}

                            <div style={{ marginBottom: '10px' }} />
                            <TextField
                                label={t('New Password')}
                                placeholder={t('Enter New Password')}
                                variant="outlined"
                                id= "np"
                                onFocus={clearNPasswordFieldError}
                                type="password"
                                fullWidth
                                sx={{ width: '70%' }}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                           
                            <div style={{ marginBottom: '10px' }} />
                            <TextField
                                label={t('Confirm New Password')}
                                placeholder={t('Confirm New Password')}
                                variant="outlined"
                                id="cp"
                                onFocus={clearCPasswordFieldError}
                                type="password"
                                fullWidth
                                sx={{ width: '70%' }}
                                value={confirmNewPassword}
                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                            />
                           
                            <div style={{ marginBottom: '10px' }} />
                            {/* <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                onClick={validation}
                                style={{ backgroundColor: "#6AA1FF" }}
                            >
                                {t('Reset Password')}
                            </Button> */}
                               <Link to="/" style={{ textDecoration: 'none' }}>
                        <Button type='submit' color='primary' variant="contained"   style={{ backgroundColor: "#6AA1FF" }}>
                            Reset My Password
                        </Button>
                    </Link>
                        </Grid>
                    </form>
                </Paper>
            </Grid>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={!!error || !!successMessage}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={error || successMessage}
            />
        </>
    );
};

export default Newpass;
