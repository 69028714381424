import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid, Paper, Button } from '@mui/material';
import { useLocation, Link } from 'react-router-dom';


const paperStyle = {
  padding: 50,
  height: 'auto',
  width: 600,
  margin: '50px auto'
}

const QuizStart = () => {
  const location = useLocation();
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    if (location.state && location.state.levelId) {
      fetchQuestions(location.state.levelId);
    }
  }, [location]);

  const fetchQuestions = async (levelId) => {
    try {
      // Fetch questions for the selected level from the backend
      const response = await axios.get(`/start-quiz?level=${levelId}`);
      setQuestions(response.data);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  return (
    
      <Grid container justifyContent="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align='Center'>
            <h1>Start Quiz</h1>
            <p>
              Welcome to the quiz! Below are the questions for your selected level.
            </p>
          <div>
            <h4>Take The Test</h4>
          </div>
          <div>
            <h5>Answer 5 Questions.</h5>
            Every child is a genius<br />
           Each question has 20 sec.<br />
           <div style={{ marginBottom: '10px' }} />
           <b>Note:</b>
           <br />If you click on an option it gets directly seleted and moves 
           <br />on next question.<br />
           If you want to change option you can select previous button.
          </div>
          </Grid>
          <Grid container spacing={2}>
            {questions.map(question => (
              <Grid item xs={12} key={question.id}>
                <p>{question.text}</p>
              </Grid>
            ))}
          </Grid>
          <div style={{ marginBottom: '10px' }} />
            <Link to='/quizapp'>
            <Button
              type='button'
             color='primary'
              variant="contained"
              style={{ backgroundColor: '#6AA1FF' ,marginTop: 20 }}
              fullWidth
           
            >
              Start Quiz
            </Button>
            </Link>
        </Paper>
      </Grid>
     
  );
};

export default QuizStart;
