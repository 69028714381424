import React, { useEffect, useState } from 'react';

const Question = ({ question, options, onAnswerClick, currentQuestion }) => {
  const [timeLeft, setTimeLeft] = useState(20);

  useEffect(() => {
    setTimeLeft(20); // Reset time for each new question
  }, [currentQuestion]);

  useEffect(() => {
    if (timeLeft === 0) {
      onAnswerClick(null); // Auto move to next question if time runs out
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, onAnswerClick]);

  return (
    <div className='question-container'>
      <div className='question'>{question}</div>
      <div className='timer'>Time left: {timeLeft}s</div>
      <div className='options'>
        {options.map((option, index) => (
          <button key={index} onClick={() => onAnswerClick(option)}>
            {option}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Question;
