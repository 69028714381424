import React, { useState } from 'react';
import axios from 'axios';
import { Grid, Paper, Button } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';

const paperStyle = {
  padding: 40,
  height: 'auto',
  width: '80%',
  margin: '50px auto',
};

const Questionlevel = () => {
  const [selectedLevel, setSelectedLevel] = useState('');
  const navigate = useNavigate();

  const handleLevelSelection = (level) => {
    setSelectedLevel(level);
    // Send selected level to the backend
    sendSelectedLevelToBackend(level);
  };

  const sendSelectedLevelToBackend = async (selectedLevel) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/quizConfigs/search/findByModuleName?moduleName=ABACUS`,
        { selectedLevel }
      );
      console.log(response.data); // Handle the response from the backend as needed
      navigate('/quizstart', { state: { levelId: response.data[0].levelId } });
    } catch (error) {
      console.error('Error sending selected level to backend:', error);
    }
  };

  const levels = [
    { name: 'Basic Level 1', id: 1 },
    { name: 'Basic Level 2', id: 2 },
    { name: 'Basic Level 3', id: 3 },
    { name: 'Basic Level 4', id: 4 },
    { name: 'Regular Level 1', id: 5 },
    { name: 'Regular Level 2', id: 6 },
    { name: 'Regular Level 3', id: 7 },
    { name: 'Regular Level 4', id: 8 },
    { name: 'Level 1', id: 9 },
    { name: 'Level 2', id: 10 },
    { name: 'Level 3', id: 11 },
  ];

  return (
    <Grid container justifyContent="center">
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          <h1>Start Practice</h1>
          <p>
            See how you perform in real-time. Put your abacus-aptitude to test.
          </p>
          <p>
            Are you done solving some questions? Great. It’s now time for you to
            race against time, and see how good you really are. Take deep
            breaths and get started with online abacus practice paper.
          </p>
        </Grid>
        <Grid container spacing={3} justifyContent="center">
          {levels.map((level) => (
            <Grid item xs={12} md={6} lg={3} key={level.id}>
              <Link to="/quizstart">
                <Button
                  color="primary"
                  variant="outlined"
                  style={{ padding: '9px 30px', width: '100%' }}
                  onClick={() => handleLevelSelection(level.id)}
                >
                  {level.name}
                </Button>
              </Link>
            </Grid>
          ))}
        </Grid>
        {/* New button below level buttons */}
        <Grid container spacing={3} justifyContent="center" style={{ marginTop: '20px' }}>
          <Grid item xs={12} md={6} lg={3}>
            <Link to="/questionPage">
              <Button
                color="secondary"
                variant="contained"
                style={{ padding: '9px 30px', width: '100%' , backgroundColor: '#6AA1FF'}}
              >
                Back to Home
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Questionlevel;
