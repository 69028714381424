import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const signInUser = async (username, password) => {
  const login_API_URL = API_URL + '/api/auth/signin';
  try {
    const response = await axios.post(login_API_URL, { username, password });
    return response.data.token;
  } catch (error) {
    throw error;
  }
};


export const forgotPassword = async (username) => {
  try {
    const forgotPasswordUrl = `${API_URL}/api/auth/forgot-password`;
    const response = await axios.post(forgotPasswordUrl, null, {
      params: {
        username: username
      }
    });
    return response.data.success;
  } catch (error) {
    throw error;
  }
};


export const resetPassword = async (token, newPassword) => {
  try {
    const resetPasswordUrl = `${API_URL}/api/auth/reset-password`;
    const response = await axios.put(resetPasswordUrl, null, {
      params: {
        token: token,
        password: newPassword
      }
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};