import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Checkbox, FormControlLabel, Grid, Paper, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import QuizAppLogo from '../../assets/images/abclogo.png';

const LoginPage = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [usernameError, setUsernameError] = useState("");
    const [passwordError, setPasswordError] = useState("");

    const paperStyle = {
        padding: 60,
        height: 'auto',
        width: 400,
        margin: "50px auto"
    };

    const validate = () => {
        let valid = true;
        setUsernameError("");
        setPasswordError("");

        if (!username) {
            setUsernameError(t('Username required*'));
            valid = false;
        }
        if (!password) {
            setPasswordError(t('Password required*'));
            valid = false;
        }

        return valid;
    };

    const clearUsernameFieldError = () => {
        setUsernameError("");
    };

    const clearPasswordFieldError = () => {
        setPasswordError("");
    };

    const handleLogin = () => {
        setMessage("");

        if (!validate()) {
            return;
        }

        setLoading(true);

        navigate("/");
    };

    return (
        <Grid container justifyContent="center">
            <Paper elevation={10} style={paperStyle}>
                <div style={{ textAlign: 'center' }}>
                    <img src={QuizAppLogo} alt="Quiz App Logo" style={{ width: '200px', height: '120px' }} />
                    <h1>Welcome</h1>
                    <div style={{ marginBottom: '20px' }} />
                </div>

                <TextField
                    label="Username"
                    placeholder="Enter Username"
                    variant="outlined"
                    id="username"
                    fullWidth
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    onBlur={validate}
                    onFocus={clearUsernameFieldError}
                    error={!!usernameError}
                    helperText={usernameError}
                />

                <div style={{ marginBottom: '10px' }} />

                <TextField
                    label="Password"
                    placeholder="Enter Password"
                    type="password"
                    id="pass"
                    variant="outlined"
                    fullWidth
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={validate}
                    onFocus={clearPasswordFieldError}
                    error={!!passwordError}
                    helperText={passwordError}
                />
                <div style={{ marginBottom: '20px' }} />

                {message && (
                    <Typography variant="body1" color="error" align="center">
                        {message}
                    </Typography>
                )}

                <div style={{ marginBottom: '20px' }} />

                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox name="checkedB" color="primary" />}
                            label="Remember me"
                        />
                    </Grid>
                    <Grid item>
                        <Typography>
                            <Link to='/forgetpassword'>Forgot password?</Link>
                        </Typography>
                    </Grid>
                </Grid>
                <div style={{ marginBottom: '20px' }} />

                <Button
                    type='submit'
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleLogin}
                    disabled={loading}
                >
                    Sign in
                </Button>

                <div style={{ marginBottom: '20px' }} />

                <div style={{ textAlign: 'center' }}>
                    <Typography>
                        New user? <Link to='/signUp'>Sign Up</Link>
                    </Typography>
                </div>
            </Paper>
        </Grid>
    );
};

export default LoginPage;
