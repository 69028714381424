import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import Header from "../../components/layout/Headers";
import Footer from "../../components/layout/Footer";

const Aboutus = () => {
    const paperStyle = { padding: 30, height: 'auto', width: 700, margin: "90px auto" };

    return (
        <>
            <Header />
            <Grid container justifyContent="center">
                <Paper elevation={10} style={paperStyle}>
                    <Grid container justifyContent="center">
                        <div style={{ textAlign: 'center' }}>
                            <h1>About Us</h1>
                            <div style={{ marginBottom: '10px' }} />
                            <h4>Learn With Us</h4>
                            <Typography>Acing Abacus Becomes Easy, Even For Beginners</Typography>
                            <div style={{ marginBottom: '60px' }} />
                            <Typography>Institute Name</Typography>
                            <Typography>Contact number: +91 0000000000</Typography>
                            <Typography>Mode of Teaching: Online (Zoom, Google Meet, etc.)</Typography>
                            <Typography>If you are trying your hand at abacus for the first time and wonder how to learn, you are in the right place.</Typography>
                            <div style={{ marginBottom: '40px' }} />
                            <Typography>With our Online quiz practice, you can master your skill and improve your level.</Typography>
                            <div style={{ marginBottom: '20px' }} />
                        </div>
                    </Grid>
                </Paper>
            </Grid>
            <div style={{ marginBottom: '10px' }} />
            <Footer />
        </>
    );
};

export default Aboutus;
