import React, { useState, useEffect } from 'react';
import { Grid, Paper, Button, Card, CardContent, Typography, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { Link } from 'react-router-dom';

const quizQuestions = [
  {
    question: "What is the capital of France?",
    options: ["Berlin", "Madrid", "Paris", "Lisbon"],
    answer: "Paris",
  },
  {
    question: "Who wrote 'Hamlet'?",
    options: ["Charles Dickens", "Jane Austen", "William Shakespeare", "Mark Twain"],
    answer: "William Shakespeare",
  },
  {
    question: "What is the largest planet in our Solar System?",
    options: ["Earth", "Jupiter", "Mars", "Saturn"],
    answer: "Jupiter",
  },
  {
    question: "Which element has the chemical symbol 'O'?",
    options: ["Gold", "Oxygen", "Silver", "Osmium"],
    answer: "Oxygen",
  },
  {
    question: "What is the square root of 64?",
    options: ["6", "7", "8", "9"],
    answer: "8",
  },
];

const Quiz = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [timeLeft, setTimeLeft] = useState(20);

  useEffect(() => {
    if (timeLeft === 0) {
      handleNextQuestion();
    }
    const timer = timeLeft > 0 && setInterval(() => setTimeLeft(timeLeft - 1), 1000);
    return () => clearInterval(timer);
  }, [timeLeft]);

  const handleAnswerOptionClick = (selectedOption) => {
    if (selectedOption === quizQuestions[currentQuestion].answer) {
      setScore(score + 1);
    }
    handleNextQuestion();
  };

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizQuestions.length) {
      setCurrentQuestion(nextQuestion);
      setTimeLeft(20); // Reset timer for next question
    } else {
      setShowScore(true);
    }
  };

  const paperStyle = { padding: 80, height: 'auto', width: '600px', margin: "50px auto" };

  return (
    <Grid>
      <Paper elevation={10} style={paperStyle}>
        <Grid align='center'>
          <Card>
            <div style={{ marginTop: "50px" }}>
              {/* You can add a title or heading here if needed */}
            </div>
            <CardContent>
              {showScore ? (
                <>
                  <Typography variant="h4" gutterBottom>
                    You scored {score} out of {quizQuestions.length}
                  </Typography>
                  <Button
                    component={Link}
                    to="/questionLevels"
                    color="primary"
                    variant="contained"
                    style={{ marginTop: '20px', backgroundColor: '#6AA1FF' }}
                  >
                    Go to Quiz Levels
                  </Button>
                </>
              ) : (
                <>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                      <Typography variant="h7" gutterBottom>
                        Question {currentQuestion + 1}/{quizQuestions.length}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="h6" gutterBottom>
                        Time: {timeLeft}s
                      </Typography>
                    </div>
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
                    <div>
                      <Typography variant="h4"  style={{ fontWeight: 'bold' }}>
                        {quizQuestions[currentQuestion].question}
                      </Typography>
                    </div>
                  </div>

                  <RadioGroup
                    aria-label="quiz-options"
                    name="quiz-options"
                    onChange={(e) => handleAnswerOptionClick(e.target.value)}
                  >
                    {quizQuestions[currentQuestion].options.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option}
                        control={<Radio />}
                        label={option}
                      />
                    ))}
                  </RadioGroup>

                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Button
                        type='button'
                        color='primary'
                        variant="outlined"
                        style={{ padding: "8px 50px", marginTop: 'auto' }}
                        onClick={() => setCurrentQuestion(currentQuestion - 1)}
                        disabled={currentQuestion === 0}
                      >
                        Previous
                      </Button>
                    </Grid>
                    <Grid item xs={5} style={{ textAlign: 'right' }}>
                      <Button
                        type='button'
                        color='primary'
                        variant="contained"
                        style={{ padding: "9px 70px", backgroundColor: '#6AA1FF', marginTop: 'auto' }}
                        onClick={handleNextQuestion}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default Quiz;
