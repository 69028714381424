import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Button,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DrawerComp from "./Drawer";

const Header = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const [authenticated, setAuthenticated] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // State to manage the anchor element for the menu

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <AppBar sx={{ background: "#0077B6" }}>
        <Toolbar>
          <Typography>Abacus</Typography>
          {isMatch ? (
            <>
              <DrawerComp />
            </>
          ) : (
            <>
              <Tabs
                sx={{ marginLeft: "auto" }}
                indicatorColor="#E6F4F1"
                color="#E6F4F1"
                value={value}
                onChange={(e, value) => setValue(value)}
              >
                <Link to="/">
                  {" "}
                  <Tab label="Home" sx={{ color: "#000000" }} />{" "}
                </Link>
                <Link to="/questionPage">
                  {" "}
                  <Tab label="Questions" sx={{ color: "#000000" }} />{" "}
                </Link>
                <Link to="/aboutUs">
                  {" "}
                  <Tab label="Aboutus" sx={{ color: "#000000" }} />{" "}
                </Link>
                <Link to="/contactUs">
                  {" "}
                  <Tab label="Contact" sx={{ color: "#000000" }} />{" "}
                </Link>
              </Tabs>
             
             
                <Link to="/signIn">
                  <Button sx={{ marginLeft: "auto" }} variant="contained">
                    Login
                  </Button>
                </Link>
           
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Header;
