import "./App.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; 

         //  Home Pages
         import Landpage from "./modules/home/landingPage";
        import Aboutus from "./modules/home/aboutUs";
         import Contactsub from "./modules/home/contactUsSubmitted";
         import Question from "./modules/home/questionPage";
         import Contactus from "./modules/home/contactUs";


         //Login
         import Signin from "./modules/login/signIn";
         import Signup from "./modules/login/signUp";
         import Forgetpass from "./modules/login/forgetPassword";
         import Newpass from "./modules/login/resetPassword";
         import Verification from "./modules/login/verification";
         import Logout from "./modules/login/logout";
         import LogoutSuccessful from "./modules/login/logoutSuccessful";
         import Changepass from "./modules/login/changePassword";

         //Pages
         import Congr from "./modules/pages/congratulation";
         import Congr2 from "./modules/pages/congratulation2";
         import Congr1 from "./modules/pages/congratulation1";

         //Profile
         import Accountinfo from "./modules/profile/accountInformation";
         import Trackprogress from "./modules/profile/trackProgress";

         //Quiz
         import Questionpg1 from "./modules/quiz/quizapp";
         import Questionpg2 from "./modules/quiz/questionPage2";
         import Questionpg3 from "./modules/quiz/questionPage3";
         import Questionpg4 from "./modules/quiz/questionPage4";
         import QuestionApp from "./modules/quiz/questionapp";
         import Questionlevel from "./modules/quiz/questionLevels";
         import QuizStart from "./modules/quiz/quizStart";

         //Components/Layout
         import Headers from "./components/layout/Headers";
         import Footer from "./components/layout/Footer";

        


function App() {
  return (
    <BrowserRouter>
      <Routes>
      {/* Home Module */}
        <Route path="/" element={<Landpage />} />
        <Route path="/aboutUs" element={<Aboutus />} />
        <Route path="/contactUs" element={<Contactus />} />
        <Route path="/contactUsSubmitted" element={<Contactsub />} />
        <Route path="/questionPage" element={<Question />} />

{/* Login Module */}
<Route path="/changePassword" element={<Changepass />} />
<Route path="/forgetpassword" element={< Forgetpass/>} />
<Route path="/logout" element={<Logout />} />
<Route path="/logoutSuccessful" element={<LogoutSuccessful />} />
<Route path="/resetPassword" element={<Newpass />} />
<Route path="/signIn" element={< Signin/>} />
<Route path="/signUp" element={< Signup/>} /> 
<Route path="/verification" element={<Verification />} />

          {/* Pages */}
<Route path="/congratulation2" element={<Congr2 />} />
<Route path="/congratulation1" element={<Congr1 />} />
<Route path="/congratulation" element={<Congr />} />
         
         {/* Profile */}
         <Route path="/accountInformation" element={<Accountinfo />} />
         <Route path="/trackProgress" element={<Trackprogress />} />

{/* Commponenta/layouts */}

        <Route path="/headers" element={<Headers />} />
        <Route path="/footer" element={<Footer />} />
       
       {/* Quiz */}
       <Route path="/questionPage2" element={<Questionpg2 />} />
        <Route path="/questionPage3" element={<Questionpg3 />} />
        <Route path="/questionPage4" element={<Questionpg4 />} />
        <Route path="/questionapp" element={<QuestionApp/>} />
        <Route path="/questionLevels" element={<Questionlevel />} />
        <Route path="/quizapp" element={<Questionpg1 />} />
        <Route path="/quizstart" element={<QuizStart />} />

       
      </Routes>
    </BrowserRouter>
  );
}

export default App;




// import React from 'react';
// import Quiz from './modules/quiz/quizapp';

// function App() {
//   return (
//     <div className="App">
//       <h1>Quiz App</h1>
//       <Quiz />
//     </div>
//   );
// }

// export default App;
