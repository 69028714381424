import React from 'react';
import { Grid, Paper, TextField, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const Forgetpass = () => {
    const paperStyle = { padding: 50, height: 'auto', width: 500, margin: "50px auto" };

    return (
        <Grid container justifyContent="center">
            <Paper elevation={10} style={paperStyle}>
                <Grid align='center'>
                    <h2>Forget your password</h2>
                    <p>Enter your Username</p>
                    <div style={{ marginBottom: '20px' }} />

                    <TextField label="Username" placeholder="Enter Username" variant='outlined'  sx={{ width: '70%' }} />
                    <div style={{ marginBottom: '10px' }} />

                    <Link to="/resetPassword" style={{ textDecoration: 'none' }}>
                        <Button type='submit' color='primary' variant="contained" >
                            Reset My Password
                        </Button>
                    </Link>

                    <div style={{ marginBottom: '20px' }} />
                </Grid>
            </Paper>
        </Grid>
    );
};

export default Forgetpass;
