import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";

const pages = [
  { label: "Home", path: "/" },
  { label: "Questions", path: "/questionPage" },
  { label: "About Us", path: "/aboutUs" },
  { label: "Contact", path: "/contactUs" },
];

const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <React.Fragment>
      <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer}>
        <List>
          {pages.map((page, index) => (
            <ListItem
              button
              key={index}
              component={Link}
              to={page.path}
              onClick={toggleDrawer}
            >
              <ListItemIcon>
                {/* Optionally, you can add icons if needed */}
                {/* <IconComponent /> */}
              </ListItemIcon>
              <ListItemText primary={page.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <IconButton
        sx={{ color: "white", marginLeft: "auto" }}
        onClick={toggleDrawer}
      >
        <MenuIcon color="white" />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
