import React from 'react';
import { Grid, Paper, Button, Typography } from '@mui/material';
import StartPractice from "../../assets/images/startPractice.jpg";
import TrackProgress from "../../assets/images/trackProgress.jpg";
import { Link } from 'react-router-dom';
import Header from "../../components/layout/Headers";
import Footer from "../../components/layout/Footer";

const Question = () => {
    const paperStyle = {
        padding: 30,
        height: '70%',
        width: '90%', // Adjusted to make it responsive
        margin: "72px auto",
        textAlign: 'center'
    };

    const imageContainerStyle = {
        display: 'flex',
        justifyContent: 'center'
    };

    return (
        <>
            <Header />
            <div style={{ textAlign: "center" }}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} md={6}>
                        <Paper elevation={10} style={paperStyle}>
                            <div style={imageContainerStyle}>
                                <img src={StartPractice} alt="" width="150" height="150" />
                            </div>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        See how you perform in real-time. Put your abacus-aptitude to test.
                                        Are you done solving some questions? Great. It’s now time for you to race against time,
                                        and see how good you really are. Take deep breaths and get started with online abacus practice paper.
                                    </Typography>
                                    <br />
                                    <Typography variant="h6">
                                        Ready to battle against time, for a full-fledged abacus test?
                                    </Typography>
                                    <br />
                                    <Link to='/questionLevels'>
                                        <Button type='submit' color='primary' variant="contained" style={{ backgroundColor: '#6AA1FF' }}>Start practice</Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper elevation={10} style={paperStyle}>

                            <div style={imageContainerStyle}>
                                <img src={TrackProgress} alt="" width="150" height="150" />
                            </div>

                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body1">
                                        A progress report serves as a communication link among students, teachers,
                                        and parents, it is a feedback which helps the students in knowing actually- where they stand.
                                        By tracking their own progress, students are encouraged to take ownership of their learning,
                                        which helps them to develop the skills needed for better performance.
                                    </Typography>
                                    <br />
                                    <Typography variant="h6">
                                        Ready to see your progress?
                                    </Typography>
                                    <br />
                                    <Link to={'/trackProgress'}>
                                        <Button type='submit' color='primary' variant="contained" style={{ backgroundColor: '#6AA1FF' }}>Track progress</Button>
                                    </Link>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <div style={{ marginBottom: '10px' }} />
            <Footer />
        </>
    );
};

export default Question;
