// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
// import frTranslation from './locales/fr.json';
import mrTranslation from './locales/mr.json';

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: enTranslation },
    // fr: { translation: frTranslation },
    mr: { translation: mrTranslation }
  },
  lng: 'mr',
  fallbackLng: 'mr',
  interpolation: { escapeValue: false }
});

export default i18n;
