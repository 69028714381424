import React, { useState } from 'react';
import axios from 'axios';
import {  Avatar, Button,Grid, Paper,TextField } from '@mui/material';
import { Link } from 'react-router-dom';



const Signup = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        username: '',
        mobile: '',
        dob: '',
        gender: '',
        TeacherFullName: '',
        city: '',
        password1: '',
        password: ''
    });

   
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const login_API_URL= process.env.REACT_APP_API_URL + "/api/auth/signup";
            console.log("Backend login API URL =", login_API_URL);

            // window.location.href = '/signin';
            
        } catch (error) {
            console.error('Error:', error.response.data); 
            
        }
    };

    const paperStyle = { padding: 40, height: 'auto', width: 450, margin: "50px auto" };



    return (
     
            <Grid>
                <Paper elevation={10} style={paperStyle}>
                    <form onSubmit={handleSubmit}>
                        <Grid align='center'>
                            <h2>Sign Up</h2>
                            <div style={{ marginBottom: '20px' }} />
                            <Grid container spacing={2}>
                            <Grid item xs={6}>
                            <TextField
                                name="firstName"
                                label="First Name"
                                placeholder="First Name"
                                variant='outlined'
                                fullWidth
                                size="small"
                                value={formData.firstName}
                                onChange={handleChange}
                            /></Grid>
                            <Grid item xs={6}>
                            <TextField
                                name="middleName"
                                label="Middle Name"
                                placeholder="Middle Name"
                                variant='outlined'
                                fullWidth
                                size="small"
                                value={formData.middleName}
                                onChange={handleChange}
                            /></Grid>
                           </Grid>
                            <div style={{ marginBottom: '10px' }} />

                         
                           

                            <Grid container spacing={2}>
                            <Grid item xs={6}>
                            <TextField
                               name="lastName"
                                label="Last Name"
                                placeholder="Last Name"
                                variant='outlined'
                                fullWidth
                                size="small"
                                value={formData.lastName}
                                onChange={handleChange}
                            /></Grid>
                            
                            <Grid item xs={6}>
                            <TextField name="email"
                                label="Email"
                                placeholder="abc@gmail.com"
                                variant='outlined'
                                fullWidth
                                size="small"
                                value={formData.email}
                                onChange={handleChange}
                            /></Grid> </Grid> 
                             <div style={{ marginBottom: '20px' }} />
                             <Grid container spacing={2}>
                            <Grid item xs={6}>
                            <TextField
                                name="username"
                                label="registrationNumber"
                                placeholder="Registration ID"
                                variant='outlined'
                                fullWidth
                                size="small"
                                value={formData.username}
                                onChange={handleChange}
                            /></Grid>
                            
                            <Grid item xs={6}>
                            <TextField
                                name="mobile"
                                label="registrationMobile"
                                placeholder="Mobile Number"
                                variant='outlined'
                                fullWidth
                                size="small"
                                value={formData.mobile}
                                onChange={handleChange}
                            /></Grid> </Grid> 
                            <div style={{ marginBottom: '20px' }} />
                               <Grid container spacing={2}>
                                <Grid item xs={6}>
                            <TextField
                                name="dob"
                                label="Date of Birth"
                                placeholder="01-01-2024"
                                variant='outlined'
                                fullWidth
                                size="small"
                                value={formData.dob}
                                onChange={handleChange}
                            />
                            </Grid>
                            <Grid item xs={6}>
                            <TextField
                                name="gender"
                                label="Gender"
                                placeholder="Male/Female"
                                variant='outlined'
                                fullWidth
                                size="small"
                                value={formData.gender}
                                onChange={handleChange}
                            />
                            </Grid>
                            <Grid item xs={6}>
                            <TextField
                                name="TeacherFullName"
                                label="Teacher Full Name"
                                placeholder="--"
                                variant='outlined'
                                fullWidth
                                size="small"
                                value={formData.TeacherFullName}
                                onChange={handleChange}
                            />
                            </Grid>
                            <Grid item xs={6}>
                            <TextField
                                name="city"
                                label="City"
                                placeholder="Enter your City"
                                variant='outlined'
                                fullWidth
                                size="small"
                                value={formData.city}
                                onChange={handleChange}
                            />
                            </Grid>
                            </Grid>
                            
                             <div style={{ marginBottom: '20px' }} />
                            <TextField
                                name="password1"
                                label="Password"
                                placeholder="******"
                                variant='outlined'
                                fullWidth
                                size="small"
                                type="password"
                                value={formData.password1}
                                onChange={handleChange}
                            />
                            <div style={{ marginBottom: '20px' }} />
                            <TextField
                                name="password"
                                label="Confirm Password"
                                placeholder="******"
                                variant='outlined'
                                fullWidth
                                size="small"
                                type="password"
                                value={formData.password}
                                onChange={handleChange}
                            />
                            <div style={{ marginBottom: '20px' }} />
                            <Button type='submit' color='primary' variant="contained" style={{ backgroundColor: '#6AA1FF' }}>Sign Up</Button>
                        </Grid>
                    </form>
                    <div style={{ marginBottom: '20px' }} />
                    Already have an account? <Link to='/signIn'>Sign In</Link>
                </Paper>
            </Grid>
           
    );
};

export default Signup;
